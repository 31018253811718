/* cabin-regular - latin */
@font-face {
    font-family: "Cabin";
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: local(''),
        url('../../assets/fonts/cabin-v26-latin-regular.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('../../assets/fonts/cabin-v26-latin-regular.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* cabin-500 - latin */
@font-face {
    font-family: "Cabin";
    font-style: normal;
    font-display: swap;
    font-weight: 500;
    src: local(''),
        url('../../assets/fonts/cabin-v26-latin-500.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('../../assets/fonts/cabin-v26-latin-500.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* cabin-600 - latin */
@font-face {
    font-family: "Cabin";
    font-style: normal;
    font-display: swap;
    font-weight: 600;
    src: local(''),
        url('../../assets/fonts/cabin-v26-latin-600.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('../../assets/fonts/cabin-v26-latin-600.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

html,
body {
    font-family: "Cabin" !important;
}