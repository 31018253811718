/* cabin-regular - latin */
@font-face {
		font-family: "Cabin";
		font-style: normal;
		font-display: swap;
		font-weight: 400;
		src: local(""), url("../../assets/fonts/cabin-v26-latin-regular.woff2") format("woff2"), url("../../assets/fonts/cabin-v26-latin-regular.woff") format("woff");
		/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* cabin-500 - latin */
@font-face {
		font-family: "Cabin";
		font-style: normal;
		font-display: swap;
		font-weight: 500;
		src: local(""), url("../../assets/fonts/cabin-v26-latin-500.woff2") format("woff2"), url("../../assets/fonts/cabin-v26-latin-500.woff") format("woff");
		/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* cabin-600 - latin */
@font-face {
		font-family: "Cabin";
		font-style: normal;
		font-display: swap;
		font-weight: 600;
		src: local(""), url("../../assets/fonts/cabin-v26-latin-600.woff2") format("woff2"), url("../../assets/fonts/cabin-v26-latin-600.woff") format("woff");
		/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
html,
body {
		font-family: "Cabin" !important;
}

.login {
		background-color: #000061;
		background-image: url("../../assets/images/png/SPBackground_1920x1080.png"), linear-gradient(90deg, #000061 2%, #0061BA 100%);
		background-size: cover;
}
.login h1 a {
		background-image: url("../../assets/images/svg/logo.svg");
		height: 50px;
		width: auto;
		background-size: auto 50px;
		background-repeat: no-repeat;
		background-position: center;
		filter: grayscale(100%) invert(100%);
}
.login #loginform {
		border-radius: 10px;
		border: none;
		box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
}
.login #loginform input:focus {
		border-color: #000061;
		box-shadow: 0 0 0 1px #000061;
}
.login #wp-submit {
		background-color: #00C7CC;
		border: solid 2px #00C7CC;
		border-radius: 50px;
		transition: all 300ms ease-out;
}
.login #wp-submit:hover {
		background-color: #fff;
		border-color: #000061;
		color: #000061;
}
.login #nav a,
.login #backtoblog a {
		color: #fff;
}
.login #nav a:hover,
.login #backtoblog a:hover {
		text-decoration: underline;
		color: #FF0038;
}