@import './src/styles/1-helpers/variables';
@import './src/styles/typography.scss';

.login {
    background-color: $clr-blue-0;
    background-image: url('../../assets/images/png/SPBackground_1920x1080.png'), $grad-blue-1;
    background-size: cover;

    h1 {
        a {
            background-image: url('../../assets/images/svg/logo.svg');
            height: 50px;
            width: auto;
            background-size: auto 50px;
            background-repeat: no-repeat;
            background-position: center;
            filter: grayscale(100%)invert(100%);
        }
    }

    #loginform {
        border-radius: 10px;
        border: none;
        box-shadow: $shadow;

        input {
            &:focus {
                border-color: $clr-blue-0;
                box-shadow: 0 0 0 1px $clr-blue-0;
            }
        }
    }

    #wp-submit {
        background-color: $clr-blue-1;
        border: solid 2px $clr-blue-1;
        border-radius: 50px;
        transition: all 300ms ease-out;

        &:hover {
            background-color: #fff;
            border-color: $clr-blue-0;
            color: $clr-blue-0;
        }
    }

    #nav,
    #backtoblog {
        a {
            color: #fff;

            &:hover {
                text-decoration: underline;
                color: $clr-red;
            }
        }
    }
}